<template>
  <div class="error-not-found">
    <div class="container">
      <div class="row error-inner text-center">
        <div class="image">
          <img src="@/assets/img/404.png" alt="404">
        </div>
        <div>
          <h1 class="heading-h3 text-black">页面不存在</h1>
          <p class="error-text text-black">请检查您访问的路径是否正确</p>
          <div class="row error-button-group">
            <router-link v-if="$route.params.from_url != '/'" class="btn-submit bg-wht" :to="$route.params.from_url">
              返回上一页
            </router-link>
            <router-link class="btn-submit" to="/">
              回到主页
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: '404 页面不存在',
    htmlAttrs: {
      lang: 'zh-cn',
      amp: true
    }
  }
}
</script>
